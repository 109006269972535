import React from "react";

import SuccessImg from "../assets/icons/Success.svg";
import { infoTexts } from "../consts";
import { useDocumentContext } from "../hooks";
import { Doc } from "./doc";
import { InfoDesc } from "./infoDesc";

const Success = () => {
    const { fileName, document, setIsLoading } = useDocumentContext();

    const openFile = () => {
        setIsLoading(true);
        return fetch(`data:application/pdf;base64,${document}`)
            .then((res) => res.blob())
            .then((blobRes) => {
                const blob = new Blob([blobRes], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(blob);
                setTimeout(() => {
                    window.open(fileURL);
                });
                setIsLoading(false);
            });
    };

    return (
        <div className="success_component">
            <img src={SuccessImg} alt="Success" />
            <InfoDesc title={infoTexts.successTitle} description={infoTexts.successDesc} />
            <Doc openFile={openFile} fileName={fileName} />
        </div>
    );
};

export default Success;
