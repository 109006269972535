import { ELoadingModes, Loading } from "@pashabank/ui-library";
import React from "react";

interface IProps {
    children?: any;
    ghost?: boolean;
    actionBtn?: () => void;
    isLoading?: boolean;
}

export const TheButton: React.FC<IProps> = ({ children, ghost = false, actionBtn, isLoading }) => {
    const buttonStyles = {
        background: ghost ? "white" : "#1CAD92",
        border: ghost ? "1px solid #1CAD92" : "none",
        color: ghost ? "#1CAD92" : "white",
    };

    return (
        <>
            <button disabled={isLoading} className="the_button" style={buttonStyles} onClick={actionBtn}>
                {isLoading ? <Loading mode={ELoadingModes.DOTS} /> : children}
            </button>
        </>
    );
};
