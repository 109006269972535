import axios from "axios";

import { ENDPOINT } from "../consts";
import { ICheckOtp, IOTPinfo } from "../models";

class DocumentService {
    getDocument = async (uuid: string) => {
        return await axios.get(`${ENDPOINT}/file/download`, { headers: { "DP-UUID": uuid } });
    };

    checkOtp = async (uuid: string, data: ICheckOtp): Promise<IOTPinfo> => {
        const res = await axios.post(`${ENDPOINT}/otp/check`, data, {
            headers: { "DP-UUID": uuid },
        });
        return await res.data;
    };

    getOTPInfo = async (uuid: string): Promise<IOTPinfo> => {
        const res = await axios.post(
            `${ENDPOINT}/otp/send`,
            {},
            {
                headers: { "DP-UUID": uuid },
            }
        );
        return await res.data;
    };
}

export const documentService = new DocumentService();
