import queryString from "query-string";
import React from "react";

import { TheButton } from "../components/button";
import { Doc } from "../components/doc";
import { InfoDesc } from "../components/infoDesc";
import { infoTexts, storageKeys } from "../consts";
import { useDocumentContext } from "../hooks";

export const SignDocument: React.FC = () => {
    const { fileName, document, setIsLoading, setUuid, isLoading, handleSendOtp } = useDocumentContext();

    const { uuid } = queryString.parse(location.search);

    React.useEffect(() => {
        if (uuid) {
            localStorage.setItem(storageKeys.UUID, JSON.stringify(uuid));
        }
    }, [uuid]);

    React.useEffect(() => {
        if (localStorage.getItem(storageKeys.UUID)) {
            setUuid(JSON.parse(localStorage.getItem(storageKeys.UUID) as string));
        }
    }, [localStorage.getItem(storageKeys.UUID)]);

    const openFile = () => {
        setIsLoading(true);
        return fetch(`data:application/pdf;base64,${document}`)
            .then((res) => res.blob())
            .then((blobRes) => {
                const blob = new Blob([blobRes], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(blob);
                setTimeout(() => {
                    window.open(fileURL);
                });
                setIsLoading(false);
            });
    };

    return (
        <div className="acquaintance">
            <InfoDesc title={infoTexts.acquaintanceTitle} description={infoTexts.acquaintanceDesc} />
            <Doc openFile={openFile} fileName={fileName} />
            <TheButton actionBtn={() => handleSendOtp(uuid as string)} isLoading={isLoading}>
                İmzalamaya keç
            </TheButton>
        </div>
    );
};
