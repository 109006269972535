import React from "react";
import { Navigate } from "react-router-dom";

import { ROUTES } from "../consts";

interface IProps {
    isVerified: boolean;
    children: React.ReactNode;
}

export const Protected: React.FC<IProps> = ({ isVerified, children }) => {
    return <>{isVerified ? <Navigate to={ROUTES.SUCCESS} replace /> : children}</>;
};
