import React from "react";

import { DocumentContext } from "./context/documentContext";
import { IOTPinfo } from "./models";

interface IDocumentContext {
    uuid: string;
    fileName: string;
    document: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setUuid: (uuid: string) => void;
    setOtpInfo: React.Dispatch<React.SetStateAction<IOTPinfo | null>>;
    otpInfo: IOTPinfo | null;
    isLoading: boolean;
    handleSendOtp: (id: string) => void;
}

export const useDocumentContext = () => {
    const { uuid, fileName, document, isLoading, setUuid, otpInfo, setOtpInfo, setIsLoading, handleSendOtp } =
        React.useContext<IDocumentContext>(DocumentContext);

    return {
        uuid,
        fileName,
        document,
        setIsLoading,
        setUuid,
        setOtpInfo,
        otpInfo,
        isLoading,
        handleSendOtp,
    };
};
