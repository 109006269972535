import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Success from "../components/Success";
import { ROUTES, storageKeys } from "../consts";
import Confirmation from "../pages/Confirmation";
import { SignDocument } from "../pages/SignDocument";
import { Protected } from "./protected";

export const RouteWrap: React.FC = () => {
    const [isVerified, setIsVerified] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
        setIsVerified(localStorage.getItem(storageKeys.ISVERIFIED) === "true");
    }, [location]);

    return (
        <Routes>
            <Route
                path=""
                element={
                    <Protected isVerified={isVerified}>
                        <SignDocument />
                    </Protected>
                }
            />
            <Route
                path={ROUTES.CONFIRMATION}
                element={
                    <Protected isVerified={isVerified}>
                        <Confirmation />
                    </Protected>
                }
            />
            <Route path={ROUTES.SUCCESS} element={<Success />} />
        </Routes>
    );
};
