import { EFileAttachmentMode, FileAttachment } from "@pashabank/ui-library";
import React from "react";

interface IProps {
    fileName: string;
    openFile: () => Promise<void>;
}

export const Doc: React.FC<IProps> = ({ fileName, openFile }) => {
    return (
        <div className="doc_view" onClick={openFile}>
            <FileAttachment
                file={{
                    name: fileName,
                    mimeType: "PDF",
                }}
                mode={EFileAttachmentMode.STATIC}
            />
        </div>
    );
};
