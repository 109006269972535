import React from "react";

interface IProps {
    title: string;
    description: string;
}

export const InfoDesc: React.FC<IProps> = ({ title, description }) => {
    return (
        <div className="container info_desc">
            <h4>{title}</h4>
            <p>{description}</p>
        </div>
    );
};
