import "./main.scss";
import "@pashabank/ui-library/ui-library.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

import { Navbar } from "./components/nav";
import { DocumentProvider } from "./context/documentContext";
import { RouteWrap } from "./routes/routeWrap";

function App() {
    const queryClient = new QueryClient();

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Navbar />
                <DocumentProvider>
                    <RouteWrap />
                </DocumentProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
