import { EElementView, EPanelSize, Panel, Text } from "@pashabank/ui-library";
import { IError, Maybe } from "@pashabank/ui-library/models";
import * as React from "react";

declare module "@pashabank/ui-library/core/models" {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface IThemeProps<T> {
        children?: React.ReactNode;
    }
}

interface IProps {
    /** Common error. */
    error: Maybe<IError>;

    /** Margin bottom. */
    marginBottom?: EPanelSize;

    /** Margin top. */
    marginTop?: EPanelSize;

    /** Validation error list. */
    validationErrors?: string[];

    /** dataTestId. */
    dataTestId?: string;
}

/**
 * Error panel - render panel with error message or list of panels with validation errors.
 */
export const ErrorPanel: React.FC<IProps> = (props) => {
    const { dataTestId, marginBottom, marginTop, error } = props;

    return (
        <Panel
            dataTestId={dataTestId}
            padding={EPanelSize.X4}
            view={EElementView.DANGER}
            marginBottom={marginBottom}
            marginTop={marginTop}
        >
            <Text>{error?.code || error?.message || "Xəta baş verdi."}</Text>
        </Panel>
    );
};

ErrorPanel.displayName = "ErrorPanel";
