import { IError, Loading } from "@pashabank/ui-library";
import React from "react";
import { useNavigate } from "react-router";

import { ErrorPanel } from "../components/errorPanel";
import { ROUTES, storageKeys } from "../consts";
import { IOTPinfo } from "../models";
import { documentService } from "../services/docService";

export const DocumentContext = React.createContext<any>({});

interface IProps {
    children: React.ReactNode;
}

declare module "@pashabank/ui-library/core/models" {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface IThemeProps<T> {
        children?: React.ReactNode;
    }
}

export const DocumentProvider: React.FC<IProps> = ({ children }) => {
    const navigate = useNavigate();
    const [uuid, setUuid] = React.useState<string>(JSON.parse(localStorage.getItem(storageKeys.UUID) as string));
    const [document, setDocument] = React.useState<string | null>(null);
    const [fileName, setFileName] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [otpInfo, setOtpInfo] = React.useState<IOTPinfo | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<IError | null>(null);

    React.useEffect(() => {
        if (uuid !== null) {
            setIsLoading(true);
            documentService
                .getDocument(uuid)
                .then(function (res) {
                    setDocument(res.data);
                    setFileName(res.headers["filename"]);
                    localStorage.setItem(storageKeys.ISVERIFIED, res.headers["is-verified"]);
                })
                .catch((err) => {
                    setErrorMessage(err.response.data);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [uuid]);

    const handleSendOtp = (id: string) => {
        setIsLoading(true);
        documentService
            .getOTPInfo(id)
            .then((res) => {
                setOtpInfo(res);
                navigate(ROUTES.CONFIRMATION);
            })
            .catch((err) => {
                setErrorMessage(err.response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <DocumentContext.Provider
            value={{
                uuid,
                document,
                fileName,
                setIsLoading,
                setUuid,
                otpInfo,
                setOtpInfo,
                isLoading,
                handleSendOtp,
            }}
        >
            <Loading visible={isLoading || uuid === null}>
                <>
                    {errorMessage !== null ? (
                        <div className="error_section">
                            <ErrorPanel error={errorMessage} />
                        </div>
                    ) : (
                        <>{children}</>
                    )}
                </>
            </Loading>
        </DocumentContext.Provider>
    );
};
