import { ELoadingModes, Loading } from "@pashabank/ui-library";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";

import { TheButton } from "../components/button";
import { InfoDesc } from "../components/infoDesc";
import VerificationInput from "../components/VerificationInput/VerificationInput";
import { infoTexts, QUERY_KEYS, ROUTES, storageKeys } from "../consts";
import { useDocumentContext } from "../hooks";
import { ICheckOtp } from "../models";
import { documentService } from "../services/docService";

const Confirmation = () => {
    const navigate = useNavigate();
    const { uuid, otpInfo, handleSendOtp } = useDocumentContext();
    const [restTime, setRestTime] = React.useState(otpInfo?.expirationTime || 60);
    const [otpCode, setOtpCode] = React.useState("");

    const { isLoading: isCheckLoading, mutate: checkOtp } = useMutation(
        [QUERY_KEYS.CHECK_OTP, uuid],
        (data: ICheckOtp) => documentService.checkOtp(uuid, data),
        {
            onSuccess: () => {
                localStorage.setItem(storageKeys.ISVERIFIED, "true");
                navigate(ROUTES.SUCCESS);
            },
            onError: (err: any) => {
                alert(err.response.data.message);
                setOtpCode("");
            },
        }
    );

    React.useEffect(() => {
        if (otpInfo) {
            setRestTime(otpInfo?.expirationTime);
        }
    }, [otpInfo?.expirationTime]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setRestTime((seconds) => seconds - 1);
        }, 1000);

        if (restTime <= 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [restTime]);

    const handleComplete = (val: string) => {
        checkOtp({
            challenge: otpInfo?.challenge ?? "",
            otpCode: val,
        });
    };

    return (
        <div>
            <div className="container">
                <InfoDesc title={infoTexts.signTitle} description={`${otpInfo?.phoneNumber} ${infoTexts.signDesc}`} />
            </div>
            {isCheckLoading ? (
                <Loading mode={ELoadingModes.DOTS} />
            ) : (
                <VerificationInput
                    onComplete={handleComplete}
                    value={otpCode}
                    autoFocus
                    onChange={(e: any) => {
                        setOtpCode(e);
                    }}
                    validChars="0123456789"
                />
            )}

            {restTime ? (
                <TheButton ghost isLoading={isCheckLoading}>
                    {infoTexts.buttonCountdownText} 00:{restTime}
                </TheButton>
            ) : (
                <TheButton actionBtn={() => handleSendOtp(uuid)} isLoading={isCheckLoading}>
                    {" "}
                    {infoTexts.buttonText}{" "}
                </TheButton>
            )}
        </div>
    );
};

export default Confirmation;
