import { AxiosRequestConfig } from "axios";

export const infoTexts = {
    acquaintanceTitle: "Sənədlərlə tanışlıq",
    acquaintanceDesc: "Aşağıdakı sənədlər toplusu ilə tanış olun, şərtləri qəbul etdiyiniz təqdirdə, imzalamaya keçin",
    signTitle: "Sənədlərin imzalanması",
    signDesc:
        "nömrəsinə 6 rəqəmli kod göndərildi. Həmin kodu aşağıdakı xanaya daxil edərək, sənədi imzalamış olacaqsınız.",
    buttonCountdownText: "Yeni kod üçün ",
    buttonText: "Yeni kod",
    successTitle: "Sənəd uğurla imzalandı",
    successDesc: "Ən qısa zamanda kartın çatdırılması ilə bağlı sizinlə əlaqə saxlanılacaq.",
};

export const commonRestConfig: AxiosRequestConfig = {
    baseURL: window.location.href.includes("localhost") ? "http://localhost:8010" : "",
};

export const ENDPOINT = `${commonRestConfig.baseURL}/v1/retail-doc-sign`;

export const QUERY_KEYS = {
    Document: "Document",
    SEND_OTP: "Send_Otp",
    CHECK_OTP: "Check_Otp",
};

export const storageKeys = {
    UUID: "uuid",
    ISVERIFIED: "isVerified",
};

export const ROUTES = {
    DOCUMENT_SIGN: "/",
    CONFIRMATION: "/confirmation",
    SUCCESS: "/success",
    ERROR: "/errorr",
    EXPIRE: "/expire",
};

export const DOCSTATUS = {
    IDLE: "IDLE",
    IS_LOADING: "IS_LOADING",
    HAS_ERROR: "HAS_ERROR",
    SUCCESS: "SUCCESS",
    FINISHED: "FINISHED",
};
